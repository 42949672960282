import * as React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import Theme from "../../../vars/ThemeOptions";

const StyledLink = styled.div`
  .cta {
    background: ${Theme.hex("primary")};
    padding: 0.75rem 1.5rem;
    margin: 0.5rem 0 1rem 0;
    display: inline-block;
    color: ${Theme.hex("white")};
    text-transform: uppercase;
    transition: all 0.3s ease;
    &:hover {
      background: ${Theme.hex("secondary")};
      color: ${Theme.hex("black")};
      transition: all 0.3s ease;
    }
  }
`;

export const BlockExternalLink = ({
  to,
  text,
  target
}: {
  to: string;
  text: string;
  target: string;
}) => {
  return (
    <StyledLink>
      <WmkLink to={to} target={target} className="cta">
        {text}
      </WmkLink>
    </StyledLink>
  );
};
