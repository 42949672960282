import { BLOCKS } from "@contentful/rich-text-types";
import * as React from "react";
import get from "lodash/get";
import { GatsbyImage } from "gatsby-plugin-image";
import { Img } from "../../classes/Img";
import { EmbeddedBlock } from "./Blocks";

export const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const references = get(node, `references`);
      const image = get(references, `gatsbyImageData`)
        ? new Img(references)
        : null;
      return image ? (
        <GatsbyImage
          image={image.gatsby}
          alt={image.title}
          style={{ margin: "0 0 2vh 0" }}
        />
      ) : null;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const entry = new EmbeddedBlock(node);
      return entry.render();
    }
  }
  //renderText: (text) => text.replace("!", "?")
};
