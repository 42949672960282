import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options as defaultOptions } from "./richTextOptions";
import * as React from "react";
import { RichText } from "../../classes/RichText";

export const RichTextReact = ({
  content,
  options
}: {
  content: RichText;
  options?: object;
}) => {
  const { json } = content;
  const bothOptions = { ...defaultOptions, ...options };
  return <>{documentToReactComponents(json, bothOptions)}</>;
};
