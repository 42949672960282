import get from "lodash/get";
import * as React from "react";
import { BlockExternalLink } from "./Blocks/ExternalLink";

export const NullComponent = () => <></>;

export class EmbeddedBlock {
  constructor(node) {
    const data = get(node, `references`);
    const type = data.__typename;
    this.props = undefined;
    this.Comp = NullComponent;
    this.blockId = get(data, `blockId`);
    // console.log(node, type);
    switch (true) {
      case type === "ContentfulExternalLink":
        console.log("yes");
        this.setProps({
          text: data.linkText,
          target: data.target === "Same Window" ? undefined : "blank",
          to: data.url
        });
        this.setComp(BlockExternalLink);
        break;
      default:
        console.log(`no block component found for data:`, node);
    }
  }
  render() {
    const Jsx = this.Comp;
    const props = this.props;
    return <Jsx {...props} />;
  }
  setProps(props) {
    this.props = props ? props : undefined;
  }
  setComp(Comp) {
    this.Comp = Comp ? Comp : NullComponent;
  }
}
